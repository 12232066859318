<template>
	<div class="doctor-list">
		<el-card class="filter-container" shadow="never">
			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="120px">

                    <el-row>
                        <el-form-item label="广告位名称：">
                            <el-input style="width:300px" v-model="keyword" placeholder="广告位名称"></el-input>
                        </el-form-item>
                        <el-form-item style="float: right;">
                            <el-button type="primary" size="small" @click="getlist()">
                                查询
                            </el-button>
                            <el-button type="info" size="small"  @click="clearlist">
                                重置
                            </el-button>
                            <el-button  type="success" size="small"  @click="addclass">
                                新增
                            </el-button>
                        </el-form-item>
                    </el-row>

				</el-form>
			</div>
		</el-card>

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>广告分类列表</span></el-col>
			</el-row>
		</el-card>

		<el-card style="margin: 20px 0; " shadow="never">
			<el-table :data="list" style="width: 100%;">
				<el-table-column label="id" align="left" prop="plugAdtypeId">
				</el-table-column>
				<el-table-column label="排序" align="center">
					<template slot-scope="scope">
						<el-input style="width: 80px;" v-model="scope.row.plugAdtypeOrder" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>

				<el-table-column label="广告位名称" align="center" prop="plugAdtypeName">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click.native.prevent="update(scope)">修改</el-button>
							<el-button size="mini" type="text" @click.native.prevent="del(scope)">删除</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>


		<!-- 弹窗 -->
		<el-dialog title="广告分类" :visible.sync="allocDialogVisible" width="60%" :modal-append-to-body="modalAppendToBody">
			<el-form ref="form" :model="form" :rules="dataRule"  label-width="100px">
				<el-form-item label="广告位名称"  prop="plugAdtypeName">
					<el-input v-model="form.plugAdtypeName" style="width: 50%;"></el-input>
				</el-form-item>
				<el-form-item label="排序">
                    <el-input v-model="form.plugAdtypeOrder" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addsavebtn()">确定</el-button>
					<el-button type="info" @click="clearshow">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="10" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '', //搜索
				list: [], //循环列表 
                form: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true,
                dataRule: {
                    plugAdtypeName: [
                        { required: true, message: '广告位名称不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			// 修改 添加提交
			addsavebtn(e) {
				const that = this;
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (that.apiurl == 'add') {
                            var apiurl = '/admin/plug/adtype_add';
                        } else if (that.apiurl == 'update') {
                            var apiurl = '/admin/plug/adtype_edit'
                        }
                        that.api(apiurl, that.form).then(res => {
                            if (res.code == 200) {
                                that.allocDialogVisible = false;
                                that.getlist();
                            }
                        })
                    }
                })
			},
			// 删除
			del(e) {
				const that = this;
                that.$confirm('您确认要删除该条广告位信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/plug/adtype_del', {
                        plugAdtypeId: e.row.plugAdtypeId
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

			},

			// 添加列表按钮
			addclass() {
				const that = this;
				that.form.plugAdtypeId = '';
				that.form.plugAdtypeName = '';
				that.form.plugAdtypeOrder = '';
				that.allocDialogVisible = true;
				that.apiurl = 'add';
			},
			// 取消
			clearshow() {
				const that = this;
				that.allocDialogVisible = false;
			},
			// 单条排序
			blur(e) {
				const that = this;
				var data = [{
					plugAdtypeId: e.row.plugAdtypeId,
					plugAdtypeOrder: Number(e.row.plugAdtypeOrder)
				}]

				that.api('admin/plug/adtype_order', data).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},

			// 修改
			update(e) {
				const that = this;
				that.form = e.row;
				that.apiurl = 'update';
				that.allocDialogVisible = true;

			},
			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/plug/adtype_list', {
					pageSize: 10,
					pageNo: that.pageNo,
					keyword: that.keyword
				}).then(res => {
					that.box = res.data;
					that.list = res.data.pageList;
					that.pageNo = res.data.pageNo;

					this.pageshow = false; //让分页隐藏
					this.$nextTick(() => { //重新渲染分页
						this.pageshow = true;
					});
				})
			},
			// 重置搜索
			clearlist() {
				const that = this;
				that.keyword = "";
				that.getlist();
			}
		},
		created() {
			const that = this;
			that.getlist();
		}
	}
</script>

<style lang="less" scoped>


</style>
